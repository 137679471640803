import { LoadingButton } from '@mui/lab';
import { Box, Button, MenuItem, styled, Select } from '@mui/material';
import { CoursesDto, LessonsDto } from 'dto';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { FormatUpload } from 'src/@types/amsEnum';
import UploadPreviewImage from 'src/common/Mui/UploadPreviewImage';
import CoursesRepository from 'src/common/repository/CoursesRepository';
import LessonsRepository from 'src/common/repository/LessonsRepository';
import {
  FormProvider,
  RHFSelect,
  RHFSwitch,
  RHFTextField
} from 'src/components/HookForm';
import { HTMLEmptyTemplate } from './empty-template';
import useValidation from './useValidation';

import {
  AdvancedType,
  BasicType,
  BlockManager,
  IPage,
  JsonToMjml
} from 'easy-email-core';
import { EmailEditor, EmailEditorProvider } from 'easy-email-editor';
import {
  ExtensionProps,
  MjmlToJson,
  StandardLayout
} from 'easy-email-extensions';
import mjml from 'mjml-browser';

import 'easy-email-editor/lib/style.css';
import 'easy-email-extensions/lib/style.css';

// theme, If you need to change the theme, you can make a duplicate in https://arco.design/themes/design/1799/setting/base/Color
import '@arco-themes/react-easy-email-theme/css/arco.css';

const MainContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
`;

const FormContainer = styled(Box)`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto auto;
`;

const EditorContainer = styled(Box)``;

const ActionsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

type EditorState = {
  subject: string;
  content: IPage;
};

// fix design small screen
function LessonInfo() {
  const { id } = useParams();
  const [image, setImage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [listCourses, setListCourses] = useState<CoursesDto[]>([]);
  const [videoSrc, setVideoSrc] = useState<string>(
    'https://www.youtube.com/embed/VIDEO_ID'
  );

  const [models, setModels] = useState<LessonsDto[]>([]);
  const [selectedModelId, setSelectedModelId] = useState<number | null>(null);
  const [selectedModelName, setSelectedModelName] = useState<string>('');
  const navigator = useNavigate();

  const defaultCategories: ExtensionProps['categories'] = [
    {
      label: 'Contenu',
      active: true,
      blocks: [
        { type: AdvancedType.TEXT },
        {
          type: AdvancedType.IMAGE,
          payload: { attributes: { padding: '0px 0px 0px 0px' } }
        },
        { type: AdvancedType.BUTTON },
        { type: AdvancedType.DIVIDER },
        { type: AdvancedType.SPACER },
        { type: AdvancedType.WRAPPER },
        { type: AdvancedType.COLUMN },
        {
          type: BasicType.RAW,
          title: 'Vidéo YouTube',
          payload: {
            data: {
              value: {
                content: `<iframe width="560" height="315" src="${videoSrc}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
              }
            },
            attributes: {
              videoLink: videoSrc
            }
          }
        }
      ]
    },
    {
      label: 'Layout',
      active: true,
      displayType: 'column',
      blocks: [
        {
          title: '2 columns',
          payload: [
            ['50%', '50%'],
            ['33%', '67%'],
            ['67%', '33%'],
            ['25%', '75%'],
            ['75%', '25%']
          ]
        },
        {
          title: '3 columns',
          payload: [
            ['33.33%', '33.33%', '33.33%'],
            ['25%', '25%', '50%'],
            ['50%', '25%', '25%']
          ]
        },
        {
          title: '4 columns',
          payload: [['25%', '25%', '25%', '25%']]
        }
      ]
    },
    {
      label: 'Autres',
      active: true,
      blocks: [
        { type: AdvancedType.CAROUSEL },
        { type: AdvancedType.ACCORDION },
        { type: AdvancedType.SOCIAL },

        { type: AdvancedType.HERO },
        { type: AdvancedType.NAVBAR }
      ]
    }
  ];

  const [editorState, setEditorState] = useState<IPage>(
    BlockManager.getBlockByType(BasicType.PAGE)!.create({})
  );

  const [initialState, setInitialState] = useState<EditorState>({
    subject: 'BeParentalis Lesson',
    content: BlockManager.getBlockByType(BasicType.PAGE)!.create({
      data: {
        value: {
          width: '600px',
          breakpoint: '0px',
          fontFamily: 'Arial, sans-serif'
        }
      }
    })
  });

  const { methods } = useValidation();
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    clearErrors,
    setValue,
    watch
  } = methods;

  const showContent = watch('haveContent');

  const handleUploadFile = async (files?: FileList) => {
    if (files) {
      clearErrors('image');
      const allowedFormats: FormatUpload[] = [
        FormatUpload.JPEG,
        FormatUpload.PNG,
        FormatUpload.GIF
      ];
      if (allowedFormats.includes(files[0]?.type as FormatUpload)) {
        setLoading(true);
        await CoursesRepository.uploadImage(files, (data) => {
          if (data) {
            setImage(data.url);
            setLoading(false);
          }
        });
      } else {
      }
    }
  };

  const exportData = (data: LessonsDto) => {
    if (showContent && editorState) {
      const mjmlContent = JsonToMjml({
        data: editorState,
        mode: 'production',
        context: editorState
      });

      const html = mjml(mjmlContent, {}).html;
      onSubmit({ ...data, content: html, mjmlContent });
    } else {
      onSubmit(data);
    }
  };

  const onSubmit = async (data: LessonsDto) => {
    if (Number(id)) {
      const res = await LessonsRepository.update(Number(id), {
        ...data,
        courseID: Number(data.courseID),
        from: Number(data.from),
        to: Number(data.to),
        image
      });

      if (res) navigator('/lesson');
    } else {
      LessonsRepository.create({
        ...data,
        courseID: Number(data.courseID),
        from: Number(data.from),
        to: Number(data.to),
        image
      }).then((res) => {
        if (res) {
          navigator('/lesson');
        }
      });
    }
  };

  const onError = () => {
    if (image === '') {
      setError('image', { message: 'Image is required !' });
    }
  };

  const fetchLessonById = async () => {
    try {
      const res = await LessonsRepository.getById(Number(id));
      console.log('fetchLessonById', res.mjmlContent);
      if (res) {
        setValue('courseID', res.courseID);
        setValue('isSubscription', res.isSubscription);
        setValue('isVisibleOnApp', res.isVisibleOnApp);
        setValue('name', res.name);
        setValue('note', res.note);
        setValue('from', res.from ?? undefined);
        setValue('to', res.to ?? undefined);
        setValue('linkedLessonID', res.linkedLessonID);

        setValue('haveContent', res.haveContent);
        setImage(res.image);
        setValue('videoLink', res.youtubeLink);

        setInitialState((state) => ({
          ...state,
          content: MjmlToJson(res.mjmlContent)
        }));
      }
    } catch (error) {
      console.error('Some error:', error);
    }
  };

  const getAllListCourse = async () => {
    const resCourses = await CoursesRepository.getList();
    if (resCourses) {
      setListCourses(resCourses.data);
    }
  };

  const [listLessonToLinked, setListLessonToLinked] = useState<LessonsDto[]>(
    []
  );

  const fetchListLessonsToLinked = async () => {
    try {
      const res = await LessonsRepository.getList({ excludeLessonIDs: id });

      if ((res.data || []).length) {
        setListLessonToLinked(res.data);
      }
    } catch (error) {
      console.error('Some error:', error);
    }
  };

  const fetchLessonModels = async () => {
    try {
      const models = await CoursesRepository.getLessonModels();
      setModels(models);
      console.log('Models loaded from API:', models);
    } catch (error) {
      console.error('Error fetching lesson models:', error);
    }
  };

  const handleModelSelect = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const modelId = Number(event.target.value); // Convert to number
    const selectedModel = models.find((model) => model.id === modelId); // Trouver le modèle correspondant

    console.log('selectedModel', selectedModel);
    console.log('modelsId', modelId);

    if (selectedModel) {
      setSelectedModelId(Number(selectedModel.courseID)); // Convert to number
      setSelectedModelName(selectedModel.name); // Mettre à jour l'état avec le nom du modèle sélectionné

      // Fetch lessons by ID
      try {
        const lessonData = await CoursesRepository.getLessonModelById(modelId);
        console.log('Fetched lesson data:', lessonData);

        // Use lessonData to populate the editor
        if (lessonData.mjmlContent) {
          setInitialState((state) => ({
            ...state,
            content: MjmlToJson(lessonData.mjmlContent) // Assuming mjmlContent is the content for the editor
          }));
        } else {
          console.error("Le lessonData n'a pas de contenu MJML valide.");
        }
      } catch (error) {
        console.error('Error fetching lesson data:', error);
      }

      if (selectedModel.mjmlContent) {
        setInitialState((state) => ({
          ...state,
          content: MjmlToJson(selectedModel.mjmlContent)
        }));
        setEditorState(MjmlToJson(selectedModel.mjmlContent));
      } else {
        console.error("Le modèle sélectionné n'a pas de contenu MJML valide.");
      }
    }
  };

  useEffect(() => {
    if (Number(id)) {
      fetchLessonById();
    } else {
      setValue('haveContent', true);
      setValue('content', HTMLEmptyTemplate);
    }
    getAllListCourse();
    fetchListLessonsToLinked();
    fetchLessonModels();
  }, [Number(id)]);

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(exportData, onError)}
      style={{ display: 'flex', flexDirection: 'row' }}
    >
      <MainContainer>
        <FormContainer>
          <RHFTextField
            label="Name *"
            placeholder="Name *"
            className="w-full"
            name="name"
          />

          <RHFTextField
            label="From *"
            placeholder="From"
            className="w-full"
            name="from"
          />

          <RHFTextField
            name="to"
            label="To *"
            placeholder="To"
            className="w-full mt-4"
          />

          <RHFTextField
            name="note"
            label="Note"
            placeholder="Note"
            className="w-full mt-4"
          />

          <RHFSelect
            fullWidth
            name="courseID"
            label="Course *"
            SelectProps={{
              native: false,
              sx: { textTransform: 'capitalize' }
            }}
          >
            {listCourses?.map((item) => (
              <MenuItem
                key={item?.id}
                value={item?.id}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize'
                }}
              >
                {item?.name}
              </MenuItem>
            ))}
          </RHFSelect>

          <RHFSelect
            fullWidth
            name="linkedLessonID"
            label="Link lesson"
            SelectProps={{
              native: false,
              sx: { textTransform: 'capitalize' }
            }}
          >
            {listLessonToLinked?.map((item) => (
              <MenuItem
                key={item?.id}
                value={item?.id}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize'
                }}
              >
                {item?.name}
              </MenuItem>
            ))}
          </RHFSelect>

          <UploadPreviewImage
            image={image}
            onUpload={handleUploadFile}
            loading={loading}
            onDelete={() => setImage('')}
            textErr={errors?.image?.message}
          />

          <RHFSwitch
            name="isVisibleOnApp"
            label="Visible sur l'application mobile"
          />

          <RHFSwitch name="isSubscription" label="Abonnement requis" />

          <RHFSwitch
            name="haveContent"
            label="Utiliser l'editeur et désactiver les cartes"
          />

          <RHFSelect
            fullWidth
            name="modelSelect"
            label={selectedModelName || 'Sélectionner un modèle'}
            value={selectedModelId || ''}
            onChange={handleModelSelect}
            SelectProps={{
              native: false,
              sx: { textTransform: 'capitalize' }
            }}
          >
            {models.map((model) => (
              <MenuItem
                key={model.id}
                value={model.id}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize'
                }}
              >
                {model.name}
              </MenuItem>
            ))}
          </RHFSelect>
        </FormContainer>

        {showContent && (
          <EditorContainer>
            <EmailEditorProvider
              data={initialState}
              height="calc(100vh - 200px)"
              autoComplete
              dashed={false}
            >
              {({ values }) => {
                setEditorState(values.content);
                return (
                  <StandardLayout categories={defaultCategories}>
                    <EmailEditor />
                  </StandardLayout>
                );
              }}
            </EmailEditorProvider>
          </EditorContainer>
        )}

        <ActionsContainer>
          <Button onClick={() => navigator('/lesson')}>Annuler</Button>
          <LoadingButton
            type="submit"
            variant="outlined"
            // loading={isSubmitting}
          >
            {Number(id) ? 'Mettre à jour la fiche' : 'Créer la fiche'}
          </LoadingButton>
        </ActionsContainer>
      </MainContainer>
    </FormProvider>
  );
}

export default LessonInfo;
