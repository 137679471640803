import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  MenuItem,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { CoursesDto, LessonsDto } from 'dto';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { FormatUpload } from 'src/@types/amsEnum';
import UploadPreviewImage from 'src/common/Mui/UploadPreviewImage';
import CoursesRepository from 'src/common/repository/CoursesRepository';
import LessonsRepository from 'src/common/repository/LessonsRepository';
import {
  FormProvider,
  RHFSelect,
  RHFSwitch,
  RHFTextField
} from 'src/components/HookForm';
import { HTMLEmptyTemplate } from './empty-template';
import useValidation from './useValidation';
import { useForm, SubmitHandler } from 'react-hook-form';

import {
  AdvancedType,
  BasicType,
  BlockManager,
  IPage,
  JsonToMjml
} from 'easy-email-core';
import { EmailEditor, EmailEditorProvider } from 'easy-email-editor';
import {
  ExtensionProps,
  MjmlToJson,
  StandardLayout
} from 'easy-email-extensions';
import mjml from 'mjml-browser';

import 'easy-email-editor/lib/style.css';
import 'easy-email-extensions/lib/style.css';

import '@arco-themes/react-easy-email-theme/css/arco.css';

const defaultCategories: ExtensionProps['categories'] = [
  {
    label: 'Contenu',
    active: true,
    blocks: [
      { type: AdvancedType.TEXT },
      {
        type: AdvancedType.IMAGE,
        payload: { attributes: { padding: '0px 0px 0px 0px' } }
      },
      { type: AdvancedType.BUTTON },
      { type: AdvancedType.DIVIDER },
      { type: AdvancedType.SPACER },
      { type: AdvancedType.WRAPPER },
      { type: AdvancedType.COLUMN }
    ]
  },
  {
    label: 'Layout',
    active: true,
    displayType: 'column',
    blocks: [
      {
        title: '2 columns',
        payload: [
          ['50%', '50%'],
          ['33%', '67%'],
          ['67%', '33%'],
          ['25%', '75%'],
          ['75%', '25%']
        ]
      },
      {
        title: '3 columns',
        payload: [
          ['33.33%', '33.33%', '33.33%'],
          ['25%', '25%', '50%'],
          ['50%', '25%', '25%']
        ]
      },
      {
        title: '4 columns',
        payload: [['25%', '25%', '25%', '25%']]
      }
    ]
  },
  {
    label: 'Autres',
    active: true,
    blocks: [
      { type: AdvancedType.CAROUSEL },
      { type: AdvancedType.ACCORDION },
      { type: AdvancedType.SOCIAL },
      { type: AdvancedType.HERO },
      { type: AdvancedType.NAVBAR }
    ]
  }
];

const MainContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
`;

const FormContainer = styled(Box)`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto auto;
`;

const EditorContainer = styled(Box)``;

const ActionsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

type EditorState = {
  subject: string;
  content: IPage;
};

const DeleteButton = styled(Button)(({ theme }) => ({
  color: theme.palette.error.main
}));

function LessonModels() {
  const { id } = useParams();
  const [image, setImage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [listCourses, setListCourses] = useState<CoursesDto[]>([]);
  const [selectedModelId, setSelectedModelId] = useState<number | null>(null);
  const navigator = useNavigate();

  const [editorState, setEditorState] = useState<IPage>(
    BlockManager.getBlockByType(BasicType.PAGE)!.create({})
  );

  const [initialState, setInitialState] = useState<EditorState>({
    subject: 'BeParentalis Lesson',
    content: BlockManager.getBlockByType(BasicType.PAGE)!.create({
      data: {
        value: {
          width: '600px',
          breakpoint: '0px'
        }
      }
    })
  });

  const { methods } = useValidation();
  const { setValue, watch, getValues } = methods;

  const showContent = watch('haveContent');

  const exportData = () => {
    const data = getValues();

    console.log('laa', showContent, editorState);

    const mjmlContent = JsonToMjml({
      data: editorState,
      mode: 'production',
      context: editorState
    });

    const html = mjml(mjmlContent, {}).html;
    onSubmit({ ...data, content: html, mjmlContent });
  };

  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] =
    useState<boolean>(false);
  const [modelToDelete, setModelToDelete] = useState<number | null>(null);
  const [openUpdateSuccessModal, setOpenUpdateSuccessModal] =
    useState<boolean>(false);

  const onSubmit = async (data: LessonsDto) => {
    console.log('data', data);

    try {
      if (selectedModelId) {
        // Update existing model
        const updatedModel = await CoursesRepository.updateLessonModel(
          selectedModelId,
          {
            name:
              data?.modelName ||
              models.find((m) => m.id === selectedModelId)?.name,
            mjmlContent: data.mjmlContent
          }
        );
        console.log('Modèle mis à jour avec succès:', updatedModel);

        setOpenUpdateSuccessModal(true);
      } else {
        const createdModel = await CoursesRepository.createLessonModel({
          name: data?.modelName,
          mjmlContent: data.mjmlContent
        });
        console.log('Modèle créé avec succès:', createdModel);

        setOpenSuccessModal(true);
      }

      await fetchLessonModels();
    } catch (error) {
      console.error(
        'Erreur lors de la création ou de la mise à jour du modèle:',
        error
      );
    }
  };

  const fetchLessonById = async () => {
    try {
      const res = await LessonsRepository.getById(Number(id));
      if (res) {
        setValue('courseID', res.courseID);
        setValue('isSubscription', res.isSubscription);
        setValue('isVisibleOnApp', res.isVisibleOnApp);
        setValue('name', res.name);
        setValue('note', res.note);
        setValue('from', res.from ?? undefined);
        setValue('to', res.to ?? undefined);
        setValue('linkedLessonID', res.linkedLessonID);
        setValue('haveContent', res.haveContent);
        setImage(res.image);

        if (res.mjmlContent)
          setInitialState((state) => ({
            ...state,
            content: MjmlToJson(res.mjmlContent)
          }));
      }
    } catch (error) {
      console.error('Some error:', error);
    }
  };

  const getAllListCourse = async () => {
    const resCourses = await CoursesRepository.getList();
    if (resCourses) {
      setListCourses(resCourses.data);
    }
  };

  const [listLessonToLinked, setListLessonToLinked] = useState<LessonsDto[]>(
    []
  );

  const fetchListLessonsToLinked = async () => {
    try {
      const res = await LessonsRepository.getList({ excludeLessonIDs: id });

      if ((res.data || []).length) {
        setListLessonToLinked(res.data);
      }
    } catch (error) {
      console.error('Some error:', error);
    }
  };

  const [models, setModels] = useState<LessonsDto[]>([]);

  const fetchLessonModels = async () => {
    try {
      const models = await CoursesRepository.getLessonModels();
      setModels(models);
      console.log('Models loaded from API:', models);
    } catch (error) {
      console.error('Error fetching lesson models:', error);
    }
  };

  const handleDeleteModel = async (id: number) => {
    try {
      await CoursesRepository.deleteLessonModel(id);
      console.log('Model deleted successfully');

      setSelectedModelId(null);
      setInitialState({
        subject: 'BeParentalis Lesson',
        content: BlockManager.getBlockByType(BasicType.PAGE)!.create({
          data: {
            value: {
              width: '600px',
              breakpoint: '0px'
            }
          }
        })
      });
      setEditorState(BlockManager.getBlockByType(BasicType.PAGE)!.create({}));
      await fetchLessonModels();
    } catch (error) {
      console.error('Error deleting model:', error);
    }
  };

  const confirmDeleteModel = (id: number) => {
    setModelToDelete(id); // Set the model ID to delete
    setOpenConfirmDeleteModal(true); // Open the confirmation modal
  };

  const handleConfirmDelete = () => {
    if (modelToDelete) {
      handleDeleteModel(modelToDelete); // Call the delete function
    }
    setOpenConfirmDeleteModal(false); // Close the confirmation modal
  };

  const handleModelSelect = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    console.log('event', event);
    const modelId = Number(event.target.value);
    setSelectedModelId(modelId);

    try {
      const selectedModel = await CoursesRepository.getLessonModelById(modelId);

      if (selectedModel) {
        if (selectedModel.mjmlContent) {
          if (selectedModel.mjmlContent.includes('<mjml>')) {
            setInitialState((state) => ({
              ...state,
              content: MjmlToJson(selectedModel.mjmlContent)
            }));
            setEditorState(MjmlToJson(selectedModel.mjmlContent));
          } else {
            console.error("Le contenu récupéré n'est pas du MJML valide.");
          }
        } else {
          console.error(
            "Le modèle sélectionné n'a pas de contenu MJML valide."
          );
        }
      }
    } catch (error) {
      console.error('Erreur lors de la récupération du modèle:', error);
    }
  };

  useEffect(() => {
    fetchLessonModels();
    if (Number(id)) {
      fetchLessonById();
    } else {
      setValue('haveContent', true);
      setValue('content', HTMLEmptyTemplate);
    }
    getAllListCourse();
    fetchListLessonsToLinked();
  }, [Number(id)]);

  const handleCloseModal = () => {
    setOpenSuccessModal(false);
  };

  const handleCloseUpdateModal = () => {
    setOpenUpdateSuccessModal(false);
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={exportData}
      style={{ display: 'flex', flexDirection: 'row' }}
    >
      <MainContainer>
        <FormContainer>
          <RHFTextField
            label="Nom du modèle *"
            placeholder="Nom du modèle *"
            className="w-full"
            name="modelName"
          />

          <RHFSelect
            fullWidth
            name="modelSelect"
            label="Sélectionner un modèle"
            value={selectedModelId || ''}
            onChange={handleModelSelect}
            SelectProps={{
              native: false,
              sx: { textTransform: 'capitalize' }
            }}
          >
            {models.map((model) => (
              <MenuItem
                key={model.id}
                value={model.id}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize'
                }}
              >
                {model.name}
              </MenuItem>
            ))}
          </RHFSelect>
        </FormContainer>

        {showContent && (
          <EditorContainer>
            <EmailEditorProvider
              data={initialState}
              height="calc(100vh - 200px)"
              autoComplete
              dashed={false}
            >
              {({ values }) => {
                setEditorState(values.content);
                return (
                  <StandardLayout categories={defaultCategories}>
                    <EmailEditor />
                  </StandardLayout>
                );
              }}
            </EmailEditorProvider>
          </EditorContainer>
        )}

        <ActionsContainer>
          <Button onClick={() => navigator('/lesson')}>Annuler</Button>
          <LoadingButton type="button" variant="outlined" onClick={exportData}>
            {selectedModelId ? 'Modifier la fiche' : 'Créer la fiche'}
          </LoadingButton>
          {selectedModelId && (
            <DeleteButton onClick={() => confirmDeleteModel(selectedModelId)}>
              Supprimer le modèle sélectionné
            </DeleteButton>
          )}
        </ActionsContainer>

        {/* Success Modal for Creation */}
        <Dialog open={openSuccessModal} onClose={handleCloseModal}>
          <DialogTitle>Succès</DialogTitle>
          <DialogContent>Votre fiche a bien été créée !</DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Fermer
            </Button>
          </DialogActions>
        </Dialog>

        {/* Confirmation Modal for Deletion */}
        <Dialog
          open={openConfirmDeleteModal}
          onClose={() => setOpenConfirmDeleteModal(false)}
        >
          <DialogTitle>Confirmation de suppression</DialogTitle>
          <DialogContent>
            Êtes-vous sûr de vouloir supprimer ce modèle ?
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenConfirmDeleteModal(false)}
              color="primary"
            >
              Annuler
            </Button>
            <Button onClick={handleConfirmDelete} color="secondary">
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>

        {/* Success Modal for Update */}
        <Dialog open={openUpdateSuccessModal} onClose={handleCloseUpdateModal}>
          <DialogTitle>Succès</DialogTitle>
          <DialogContent>Votre modèle a bien été modifié !</DialogContent>
          <DialogActions>
            <Button onClick={handleCloseUpdateModal} color="primary">
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </MainContainer>
    </FormProvider>
  );
}

export default LessonModels;
