import { searchDto, CoursesDto, filesUpload, SwapCoursesDto } from 'dto';

import http from '../util/http';
const CoursesRepository = {
  async getList(params?: searchDto) {
    const result = await http.get('/admin/courses', {
      params: params || undefined
    });
    return result.data;
  },

  async getById(id: number) {
    const result = await http.get(`/admin/courses/${id}`);
    return result.data;
  },

  async create(body: CoursesDto) {
    const result: CoursesDto = await http.post<CoursesDto>(
      '/admin/courses',
      body
    );
    return result;
  },

  async swap(body: SwapCoursesDto) {
    const result: SwapCoursesDto = await http.put<SwapCoursesDto>(
      '/admin/courses/swap',
      body
    );
    return result;
  },

  async update(id: number, body: CoursesDto) {
    const result: CoursesDto = await http.put<CoursesDto>(
      `/admin/courses/${id}`,
      body
    );
    return result;
  },

  async delete(id: number) {
    const result = await http.delete(`/admin/courses/${id}`);
    return result.data;
  },

  async uploadImage(files: FileList, cb?: (data: filesUpload) => void) {
    const formData = new FormData();
    formData.append('file', files[0]);
    await http
      .multipart<
        {
          data: filesUpload;
        },
        true
      >('/files', formData)
      .then((res) => {
        if (res) {
          cb?.(res.data);
        }
      })
      .catch((error) => console.log(error));
  },

  async createLessonModel(body: { name: string; mjmlContent: string }) {
    const result = await http.post('/admin/lesson-models', body);
    return result.data;
  },

  async getLessonModels() {
    const result = await http.get('/admin/lesson-models');
    return result.data;
  },

  async getLessonModelById(id: number) {
    const result = await http.get(`/admin/lesson-models/${id}`);
    return result.data;
  },

  async getLessonModelByName(name: string) {
    const result = await http.get(`/admin/lesson-models`, {
      params: { name }
    });
    return result.data;
  },

  async updateLessonModel(
    id: number,
    body: { name: string; mjmlContent: string }
  ) {
    const result = await http.put(`/admin/lesson-models/${id}`, body);
    return result.data;
  },
  async deleteLessonModel(id: number) {
    const result = await http.delete(`/admin/lesson-models/${id}`);
    return result.data;
  }
};

export default CoursesRepository;
